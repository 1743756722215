.industryView {
    position: relative;
    height: 44px;
    border: 1px solid #767676;
    letter-spacing: 0;
    max-width: 394px;
}                  
.industryCenter{
    display:flex;
    font-size: 16px;
     margin-top:11px;
     height: 44px;
    margin-right: 15px;
    margin-left:15px ;
    word-wrap: break-all;
}
.industryText{
    width: 100%;
    color: #252525;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.industrySpan{
    width: 100%;
    color: #767676;
}
.industryBottom{
    margin-bottom:26px ;
}
.heading{
    font-weight: 400;
    font-size: 24px;
    font-family: UniversNextforHSBC, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.pb-ModalDialog__open .pb-ModalDialog__underlay {
    min-height: calc(100vh - 30px);
}

/* Add focus styles for Piggybank Select and Button components */
#financialModalForm select:focus {
  border-color: rgb(51, 51, 51);
  outline: none;
  box-shadow: 0px 0px 0 1px rgba(51, 51, 51) inset;
}

#financialModalForm button:focus {
    outline-color: rgb(51, 51, 51);
}