 .inputkeyWord {
  padding: 15px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  background: #fff;
}
.searchElement{
  background: #f7f7f7;
  width: 100%;
  height: 100vh;
}
.search{
  width: 18px;
  position: absolute;
  top: 26px;
  left: 40px;
}
.inputClasss {
  width: 100%;
  height: 40px;
  margin: 0;
  border-radius: 0;
  background: #f7f7f7;
  padding-left: 45px;
  border-width: 0;
}
.charElementBg{
  background: #f7f7f7;
  word-break: break-all;
}
.charElement{
    font-size: 13px;
    color: #333333;
    letter-spacing: 0;
    padding: 10px 15px;
}
.country{
  display: -webkit-box; 
  font-size: 16px;
  color: #333333;
  letter-spacing: 0.19px;
  padding: 15px;
  background: #FFFFFF;
  border-bottom: 1px solid #EDEDED;
  line-height: 20px;
}
.clear{
  position: absolute;
  top: 28px;
  right: 42px;
  width: 15px;
}
.sr-only{
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.seachName{
  text-align: left;
  margin-right: 10px;
  padding-right: 15%;
  padding-top: 0px;
  padding-bottom: 0px;
  width: inherit;
  word-break: break-all;
}
.name {
  text-align: left;
  margin-right: 10px;
  padding-right: 10%;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 100%;
 }
 .charContent{
    top: 126px;
    right: 10px;
    position: fixed;
    letter-spacing: 0;
    text-align: right;
    line-height: 17px;
 }
 .charContent li{
    padding: 0;
    width: auto;
    list-style-type: none;
    text-align: right;
    font-size: 13px;
    color: #333333;
 }
.error {
  display: -webkit-box;
  padding: 15px !important;
  font-size: 13px;
  color: #333333;
  background: #fff;
  text-align: center;
  margin-top: 2px;
}
.errorInfo {
  width: 93%;
}