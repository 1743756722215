/*.pageWrapper {*/
/*  border-bottom: solid 42px var(--black);*/
/*}*/

.homeLink {
  height: 100%;
}
.nonBorder header > div {
   border-bottom-color: var(--white) !important;
}

html:active{
  background-color: #FFF;
}

@media screen and (min-width: 600px){
  .pageWrapper {
    border-bottom: 0;
  }
}
