.strong {
    font-weight: bold;
}

.table {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-top:0 !important;
}

.table:not(:first-child) {
    border-top:1px solid white;
}

.table li {
    flex: 0 50%;
    padding: 10px;
    border-bottom: 1px solid white;
    align-items: center;
    margin-bottom:0;
    font-size: 80%;
}

.table li:nth-child(odd){
    border-right:1px solid white;
}

.light-color{
    background: #ECEDEE;
}
.dark-color{
    background: #C6C7C8;
}

.normal {
    font-weight: normal;
}

.indent {
    padding-left:1.5em;
}
