.customReveal .pb-Reveal__Reveal--accentBar {
    padding-left: 0;
}

.customReveal .pb-Reveal__accentBar {
    padding-left: 20px;
}

.disabledFund {
   display: none;
}
.Declaration-left{
    text-indent: -1em;
    margin-left: 1em;
}
.review dt{
    font-size: 16px;
    color: #767676;
}
.review dd{
    font-size: 16px;
    color: #252525;
    font-weight: 545;
}